<template>



   <footer class="iq-footer">

<!-- modal nueva unidad -->
     <b-modal no-close-on-backdrop centered size="xl" 
                hide-footer
                   v-model="dialog"
                title="Contacto de soporte técnico">
            <b-card
                    tag="article"
                    class="mb-2">
                <b-card-text>

                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>

                    <b-form-textarea
                        ref="descripcion"
                        v-model="mensaje"
                        placeholder="Espacio para redactar su problema..."
                        rows="4"
                        max-rows="7"
                    ></b-form-textarea>      

                    <b-row class="d-flex align-items-start justify-content-start my-1">
                          <div class="col-sm-6 my-2">
                                <label for="">Adjuntar imagen</label>
                                <b-form-file class="my-3"
                                    size="sm"
                                    v-model="imagen"
                                    :state="Boolean(imagen)"
                                    placeholder="Archivo adjunto..."
                                    accept="image/*"
                                    drop-placeholder="Arrastrar un archivo para adjuntar."
                                ></b-form-file>
                          </div>
                          <div class="col-sm-6 my-2">
                        <!-- 
                            <label for="">Prioridad</label>
                              <b-form-select 
                                    class="custom-select custom-select-sm"
                                    placeholder="Prioridad"
                                     v-model="ticketPriority_id"
                                    :options="options"
                            ></b-form-select>
                            -->                                 
                          </div>                          
                    </b-row>
                    <b-row class="d-flex align-items-start justify-content-start my-1">
                                <div class="col-sm-12 my-2">
                                    <b-button 
                                            variant="primary" 
                                            size="sm" 
                                            class="float-right mx-2 my-1"
                                            @click="cancelarEnvioTicket()"
                                        >
                                    Volver
                                    </b-button>   

                                    <b-button 
                                        variant="success" 
                                        size="sm" 
                                        class="float-right mx-2 my-1"
                                        @click="sendTicket()"
                                    >
                                    Enviar
                                    </b-button> 
                            </div>
                    </b-row>
    
                                   


                      </b-form>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>

    <div class="container-fluid">
        <div class="row">
            
            <div class="col-lg-4">
                <ul class="list-inline mb-0">
                    <li class="list-inline-item"><router-link :to="{name: 'app.privacypolicy'}">    <small> Política de privacidad</small></router-link></li>
                    <li class="list-inline-item"><router-link :to="{name: 'app.Termsofuse'}">       <small> Terminos de uso</small></router-link></li>
                </ul>
            </div>

          <div class="col-lg-4 text-right">
            <b-img v-if="$store.getters.usuario.escuela_id==3" style="width: 400px;" src="img/logoFotter.png"></b-img>
            <b-img v-if="$store.getters.usuario.escuela_id==4" style="width: 400px;" src="img/estudioappLogo-Salvador.png"></b-img>
          </div>

            <div class="col-lg-4 text-right">
                <b-button 
                    @click="ShowModalEnvioTicket()"
                    pill 
                    variant="link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                    </svg>
                </b-button>
                <span class="white--text">
                    <small> <a href="https://www.nive.la" target="blank" class="white--text"
                    > 
                    nivelA
                    </a>
                    &copy; {{ new Date().getFullYear() }} </small>
                </span>
            </div>

        </div>
    </div>
</footer>
</template>
<script>
import { mapGetters } from 'vuex'
import Bugsnag from '@bugsnag/js';
export default {
    name:"Footer",
    prop:{
    },
    
    data() {
        return {
            imagen : null,
            dialog: false,
            mensaje: '',
            ticketPriority_id:0,
              options: [
                        { value: 1, text: 'Baja' },
                        { value: 2, text: 'Media' },
                        { value: 3, text: 'Alta' }
                        ]
        }
    },
    computed : {
        ...mapGetters({
            appName: 'appName'
        })
    },
    methods: {
        cancelarEnvioTicket() {
            this.resetForm();
            this.dialog   =false;
        }

        ,ShowModalEnvioTicket(){
           this.dialog   =true;
        }

        ,async sendTicket(){
            try {
              this.dialog = false;
              this.toast('b-toaster-bottom-center', true, "Enviando el ticket de ayuda 🛠️...un momento", 'info' );
               if(this.mensaje == '' || this.mensaje == ' ' || this.mensaje == null ){
                    this.toast('b-toaster-bottom-center', true, "El campo mensaje no puede estar vacío", 'danger' );
                    return false;
                }

                let formData = new FormData();
                    formData.append("imagen",               this.imagen);
                    formData.append("mensaje",              this.mensaje);
                    formData.append("hostname",             window.location.hostname);
                    formData.append("href",                 window.location.href);
                    formData.append("pathname",             window.location.pathname);

                const url = `/api/save-ticket`;
                const response = await this.$api.post(url, formData, 
                      { 
                        headers: {'content-type': 'multipart/form-data'} 
                      }
                    );
            let { message
                , color
                ,success
                } = response.data;
                
                if(success){
                        this.toast('b-toaster-bottom-center', true, message, color );
                        this.cancelarEnvioTicket();
                        }
            } catch (error) {
                  Bugsnag.notify(
                                new Error('Método: sendTicket() ' + error)
                            );
            }
                  
                    this.dialog=false;
                }//sendticket

        ,resetForm(){
             this.mensaje='';
             this.imagen = [];

        }
        ,toast(toaster, append = false,content,color) {
            this.counter++
            this.$bvToast.toast(content, {
                title: 'Nivelando',
                autoHideDelay: 3500,
                toaster: toaster,
            // solid: true,
                variant: color,
                appendToast: append
            });
        },

    },//methods

}
</script>
<style scoped>
path { fill: orange; }
</style>