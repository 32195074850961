<template>
 <div class="iq-top-navbar">
	<div class="iq-navbar-custom">
		<nav class="navbar navbar-expand-lg navbar-light p-0">
			<div class="side-menu-bt-sidebar">
                <svg    xmlns="http://www.w3.org/2000/svg"
                        class="text-secondary wrapper-menu"
                        width="30"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        @click="miniSidebar">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </div>
<!--            <div v-else>
			<div class="side-menu-bt-sidebar" style="visibility: hidden" v-if="$store.getters.usuario.role_id==3">
                <svg    xmlns="http://www.w3.org/2000/svg"
                        class="text-secondary wrapper-menu"
                        width="30"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        @load="miniSidebar"
                        >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </div>
            </div>-->
			<div class="d-flex align-items-center">
			<!-- 	<ModeSwitch /> -->
				<b-navbar-toggle target="nav-collapse" class="">
					<svg xmlns="http://www.w3.org/2000/svg" class="text-secondary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
					</svg>
				</b-navbar-toggle>
				<b-collapse id="nav-collapse" is-nav>
					<ul class="navbar-nav ml-auto navbar-list align-items-center">

                        <li class="nav-item nav-icon dropdown"  v-nav-toggle>
                            <a @click="showModal()" href="#" class="mx-3 search-toggle dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-envelope text-secondary" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                </svg>
                                <span class="bg-primary"></span>
                            </a>
                        </li>

                        <!-- MENU NOTIFICACIONES -->
                        <li style="display:none" class="nav-item nav-icon dropdown"  v-nav-toggle>

                            <a href="#" class="mx-3 search-toggle dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="h-6 w-6 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                </svg>
                                <span class="bg-primary"></span>
                            </a>

                            <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div class="card shadow-none m-0 border-0">
                                    <div class="p-3 card-header-border">
                                        <h6 class="text-center">
                                            Notificationes
                                        </h6>
                                    </div>
                                </div>
                            <div class="card-body overflow-auto card-header-border p-0 card-body-list" style="max-height: 500px;">
                                <ul class="dropdown-menu-1 overflow-auto list-style-1 mb-0">
                                    <li class="dropdown-item-1 float-none p-3">
                                        <div class="list-item d-flex justify-content-start align-items-start">
                                            <div class="avatar">
                                                <div class="avatar-img avatar-danger avatar-20">
                                                    <span>
                                                        <svg class="icon line" width="30" height="30" id="cart-alt1" stroke="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3,3H5.32a1,1,0,0,1,.93.63L10,13,8.72,15.55A1,1,0,0,0,9.62,17H19" style="fill: none;stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path><polyline points="10 13 18.2 13 21 6" style="fill: none;stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></polyline><line x1="20.8" y1="6" x2="7.2" y2="6" style="fill: none;stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></line><circle cx="10.5" cy="20.5" r="0.5" style="fill: none;stroke-miterlimit: 10; stroke-width: 2;"></circle><circle cx="16.5" cy="20.5" r="0.5" style="fill: none;stroke-miterlimit: 10; stroke-width: 2;"></circle></svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="list-style-detail ml-2 mr-2">
                                                <h6 class="font-weight-bold">Your order is placed</h6>
                                                <p class="m-0">
                                                    <small class="text-secondary">If several languages coalesce</small>
                                                </p>
                                                <p class="m-0">
                                                    <small class="text-secondary">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary mr-1" width="15" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    3 hours ago</small>
                                                </p>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            </div>


                        </li><!--NOTIFICACIONES-->


                        <li class="nav-item nav-icon search-content" v-nav-toggle>
                            <a href="#" class="search-toggle rounded dropdown-toggle" id="dropdownSearch" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg class="svg-icon text-secondary" id="h-suns" height="25" width="25" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </a>
                            <div class="iq-search-bar iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownSearch">
                                <form action="#" class="searchbox p-2">
                                    <div class="form-group mb-0 position-relative">
                                    <input type="text" class="text search-input font-size-12" placeholder="type here to search...">
                                    <a href="#" class="search-link">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg>
                                    </a>
                                    </div>
                                </form>
                            </div>
                        </li>
                        <li class="nav-item nav-icon dropdown" v-nav-toggle>
                            <a href="#" class="nav-item nav-icon dropdown-toggle pr-0 search-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                              <b-img
                                class="img-fluid avatar-rounded"
                                fluid
                                :src ="this.$store.getters.usuario.avatar"
                            ></b-img>
                                <span class="mb-0 ml-2 user-name">
                                    {{this.$store.getters.usuario.name}}

                                </span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                 <!--
								<li class="dropdown-item d-flex svg-icon">
                                    <svg class="svg-icon mr-0 text-secondary" id="h-01-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <router-link :to="{name: 'app.user-profile'}">My Profile</router-link>
                                </li>
                                <li class="dropdown-item d-flex svg-icon">
                                    <svg class="svg-icon mr-0 text-secondary" id="h-02-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                    </svg>
                                    <router-link :to="{name: 'app.user-profile-edit'}">Edit Profile</router-link>
                                </li>
								-->
                                <li class="dropdown-item  d-flex svg-icon border-top">
                                    <svg class="svg-icon mr-0 text-secondary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                    </svg>
								<b-button variant="link" @click="logout">Salir</b-button>

                                </li>

                            </ul>
                        </li>
					</ul>
				</b-collapse>
			</div>
		</nav>
	</div>

    <b-modal
        v-model="ModalNovedades"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        scrollable
        title="Información"
        size="xl"
        centered
        >
            <b-container>
                <b-row class="text-justify">
                    <b-col cols="12">
                        <b-card no-body>
                            <b-tabs class="p-4" pills vertical >
                                <b-tab active>
                                       <template #title>
                                          <div class='text-right'>
                                                <b-jumbotron class="mt-2" lead="Novedades"></b-jumbotron>
                                          </div>
                                        </template>
                                    <b-card-text>

                                        <b-list-group v-if="this.novedades.length>0">
                                            <b-list-group-item class="p-3" v-for="(item) in this.novedades" :key="item.id">
                                                <div style="display: flex; justify-content: space-between;" @click="showModalDetail(item)">
                                                        <span class="btn-block" >
                                                        {{  item.titulo}}
                                                        </span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                        </svg>
                                                </div>
                                            </b-list-group-item>
                                        </b-list-group>
                                            <div v-else class="estilo_noticias_seccion_vacia">
                                                <b-jumbotron lead="No hay noticias en esta categorías"></b-jumbotron>
                                            </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab>
                                    <template #title>
                                        <div class='text-right'>
                                            <b-jumbotron class="mt-2" lead="Mantenimiento"></b-jumbotron>
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <b-list-group v-if="this.manteinimentoPublicadas.length>0">
                                            <b-list-group-item class="p-3" v-for="(item) in this.manteinimentoPublicadas" :key="item.id">
                                                <div style="display: flex; justify-content: space-between;" @click="showModalDetail(item)">
                                                        <span class="btn-block" >
                                                        {{  item.titulo}}
                                                        </span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                        </svg>
                                                </div>
                                            </b-list-group-item>
                                        </b-list-group>
                                        <div v-else class="estilo_noticias_seccion_vacia">
                                            <b-jumbotron lead="No hay noticias en esta categorías"></b-jumbotron>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab>
                                    <template #title>
                                        <div class='text-right'>
                                            <b-jumbotron class="mt-2" lead="Problemas"></b-jumbotron>
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <b-list-group v-if="this.problemasPublicadas.length>0">
                                            <b-list-group-item class="p-3" v-for="(item) in this.problemasPublicadas" :key="item.id">
                                                <div style="display: flex; justify-content: space-between;" @click="showModalDetail(item)">
                                                        <span class="btn-block" >
                                                        {{  item.titulo}}
                                                        </span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                        </svg>
                                                </div>
                                            </b-list-group-item>
                                        </b-list-group>
                                            <div v-else class="estilo_noticias_seccion_vacia">
                                                <b-jumbotron lead="No hay noticias en esta categorías"></b-jumbotron>
                                            </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
            <template #modal-footer>
                <div class="w-100">

                        <b-button
                            @click="hideModal()"
                            variant="link"
                            size="sm"
                            class="float-right mx-2 btn-studioAPP">
                            Aceptar
                        </b-button>

                </div>
            </template>
    </b-modal>

    <b-modal
        v-model="ModalNovedadesDetalle"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        scrollable
        title="Noticias"
        size="xl"
        centered
        >
            <b-container >
                <b-row class="text-justify">
                    <b-col cols="12">
                        <b-card
                            class="p-5 mx-5 mt-2"
                            no-body>

                            <div class='p-2 text-center'>
                                <b-jumbotron class="mt-2" :lead="this.titulo">  <small>{{this.fecha }}-</small></b-jumbotron>
                            </div>
                            <hr>
                            <b-card-text>
                                 <!-- {{this.descripcion}}-->

                                <span v-html="this.descripcion"></span>

                            </b-card-text>

                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
            <template #modal-footer>
                <div class="w-100">

                        <b-button
                            @click="hideModalDetail()"
                            variant="link"
                            size="sm"
                            class="float-right mx-2 btn-studioAPP">
                            Volver
                        </b-button>

                </div>
            </template>
    </b-modal>

</div>
</template>
<script>
import {core} from '@/config/pluginInit';
import { mapGetters } from 'vuex';


export default {
    name:"HeaderStyle1",
    computed : {

        ...mapGetters({
            logo:'darklogo',
            sidelogo:'logo',
        })


    },
    mounted () {
        this.getNovedades();
        this.getMantenimiento();
        this.getProblemas();

        //quitar
         const elementExist = this.checkElement('class', 'wrapper-menu')
            if (elementExist) {
                const wrapperMenu = document.querySelector('.wrapper-menu');
                const body = document.querySelector('body');
                wrapperMenu.classList.toggle('close');
                body.classList.toggle('sidebar-main');
            }
    },
	methods: {
/*      miniSidebar(){
        core.triggerSet()
      },*/

        async getProblemas(){
            const response                        = await this.$api.get(`/api/novedades/problemas`);
            let {  problemasPublicadas }          = response.data;
            this.problemasPublicadas              = problemasPublicadas;
                console.log(problemasPublicadas);
        },

        async getMantenimiento(){
            const response                            = await this.$api.get(`/api/novedades/mantenimiento`);
            let {  manteinimentoPublicadas }          = response.data;
            this.manteinimentoPublicadas              = manteinimentoPublicadas;
                console.log(manteinimentoPublicadas);
        },

        async getNovedades(){

            const response                  = await this.$api.get(`/api/novedades/novedades`);
            let {  novedadesPublicadas }    = response.data;
            this.novedades                  = novedadesPublicadas;
                console.log(this.novedades );
        },

        hideModalDetail(){
            this.ModalNovedadesDetalle = false;
        },
        showModalDetail(item){
            this.fecha           =item.fecha;
            this.descripcion     = item.descripcion;
            this.titulo         = item.titulo;
            this.ModalNovedadesDetalle = true;
        },

        showModal() {
            this.ModalNovedades = true;
        },
        hideModal(){
            this.ModalNovedades=false;
        },

        //quitar
          checkElement (type, element) {
            let found = false
            let elements
            switch (type) {
                case 'class':
                    elements = document.getElementsByClassName(element)
                    if (elements !== undefined && elements !== null && elements.length > 0) {
                    found = true
                    }
                    break

                case 'id':
                    elements = document.getElementById(element)

                    if (elements !== undefined && elements !== null) {
                    found = true
                    }
                    break
            }
            return found
  },
		miniSidebar(){
            core.triggerSet();

        },
	async logout() {
                await this.$store.dispatch('updateIsOnline', this.$store.getters.usuario.id);
                window.location =process.env.VUE_APP_API_URL+"/logout";

/*
            const config = {  headers: { 'content-type': 'multipart/form-data' }  }
            let url      = `/logout`;
            let formData = new FormData();

            const  response   = await this.$api.post(url, formData, config );
            let {  success } = response.data;

                        if(success){
                            window.location =
                            "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" +
                            process.env.VUE_APP_API_URL +
                            "/redirect";
                        }
               */

		//	this.$api.post("/logout").then(() => {

		//	});
			}//logout
	},
    data(){
        return{
            imageMainProps: {  width: 250, height: 250 },
            extraPages: ['app.user-Account-setting','app.user-privacy-setting'],
            auth: ['auth.login'],
            user: ['app.user-profile','app.user-profile-edit'],
            ModalNovedades: false,

            ModalNovedadesDetalle:false,

            novedades:[],
            novedadesPublicadas:[],
            manteinimentoPublicadas:[],
            problemasPublicadas:[],

            fecha:'',
            titulo:'',
            descripcion:'',
        }
    }
}
</script>

<style>
    .modal-content{
          height: 95% !important;
    }

    .estilo_noticias_seccion_vacia{
        display: flex;
        align-items:center;
        height:300px;
        justify-content:center;
        border:1px solid #dedede;
        border-radius: 10px;
    }

</style>